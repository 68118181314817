<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="editDialog"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
  >
    <div class="bar">
      <li class="width50">
        <span>组别：</span>
        <el-select
          v-model="formData.group"
          @visible-change="selectListDict($event)"
          filterable
          placeholder="请选择"
          clearable
          :disabled="type == 'edit'"
          class="childWidth"
          no-data-text="加载中···"
        >
          <el-option
            v-for="(item, index) in dictList"
            :key="index"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </li>
      <li class="width50">
        <span>名称<i class="red">*</i>：</span>
        <el-input v-model="formData.name" class="childWidth"></el-input>
      </li>
      <li class="width50">
        <span>助记码：</span>
        <el-input
          :disabled="type == 'edit'"
          v-model="formData.code"
          class="childWidth"
        ></el-input>
      </li>

      <!-- <li class="width50">
        <span>编号：</span>
        <el-input :disabled="type == 'edit'" v-model="formData.value" class="childWidth"></el-input>
      </li> -->
      <li class="width50">
        <span>描述：</span>
        <el-input v-model="formData.description" class="childWidth"></el-input>
      </li>
      <!-- <li class="width50">
        <span>排序：</span>
        <el-input v-model="formData.sort" class="childWidth"></el-input>
      </li> -->
      <li class="width50">
        <span>是否启用：</span>
        <el-switch
          v-model="formData.enabled"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="1"
          inactive-value="0"
        >
        </el-switch>
      </li>
    </div>

    <div class="dialog-footer">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Vue from "vue";
import { addDict, editDict, findDetailDict } from "@/api/dataManagement";
import { selectListDict } from "@/api/dataManagement";

export default {
  name: "edit",
  data() {
    return {
      title: "新增",
      token: null,
      formData: {
        enabled: "1",
      },
      dictList: [],
    };
  },
  props: {
    type: String,
    editDialog: Boolean,
    id: String,
    name: String,
  },
  components: {},
  created() {
    // this.getQualityList();
    if (this.type == "edit") {
      this.title = this.name + " ——— 编辑";
      this.findDetailDict();
      // let formData = {};
      // formData.name = this.name;
      // formData.id = this.id;
      // console.log(formData);
      // this.formData = formData;
    } else if (this.type == "add") {
      this.title = "新增";
    }
  },
  methods: {
    selectListDict(cb) {
      if (cb) {
        let info = { condition: 1 }; //1:全部查询，2:只查最大一级
        selectListDict(info).then((response) => {
          if (response.success) {
            this.dictList = response.data;
          }
        });
      }
    },
    findDetailDict() {
      findDetailDict(this.id).then((response) => {
        this.formData = response.data;
        this.formData.enabled = String(this.formData.enabled);
      });
    },
    //弹窗关闭
    dialogClose() {
      this.formData = {};
      this.$emit("listenEditClose", { type: "cancel", isClose: true });
    },

    addDict() {
      addDict(this.formData).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "添加成功!" });
          this.formData = {};
          this.$emit("listenEditClose", { type: "sure", isClose: true });
        }
      });
    },
    editDict() {
      editDict(this.formData).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "编辑成功!" });
          this.formData = {};
          this.$emit("listenEditClose", { type: "sure", isClose: true });
        }
      });
    },
    //确定
    sure() {
      // let { serviceArea, id } = this.formData;
      // console.log(this.formData);
      if (!this.formData.name) {
        this.$message({ type: "warning", message: "请填写名称!" });
        return false;
      }
      if (this.type == "add") {
        this.addDict();
      } else if (this.type == "edit") {
        this.editDict();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.red {
  color: red;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}
</style>