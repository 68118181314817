<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2022-01-06 15:13:00
 * @LastEditTime: 2022-01-06 16:33:11
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/dataManagement/associatedChildNode.vue
-->
<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="associatedChildNodeDialog"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      ref="associatedChildNode"
      :rules="rules"
      class="addDailog"
      onsubmit="return false;"
      label-width="140px"
      label-position="right"
    >
      <el-form-item label="子节点" prop="childrenIdList">
        <el-select
          v-model="formData.childrenIdList"
          @visible-change="selectListDict($event)"
          filterable
          placeholder="请选择"
          clearable
          class="childWidth"
          no-data-text="加载中···"
          multiple
        >
          <el-option
            v-for="(item, index) in dictList"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
let that = this;
import { selectListDict, associatedChildNode } from "@/api/dataManagement";

export default {
  name: "associatedChildNode",
  data() {
    return {
      title: "关联子节点",
      formData: {
        id: this.id,
        childrenIdList: [],
      },
      rules: {
        childrenIdList: [
          { required: true, message: "请选择子节点", trigger: "change" },
        ],
      },
      dictList: [],
    };
  },
  props: {
    type: String,
    associatedChildNodeDialog: Boolean,
    id: String,
    name: String,
    childrenIdList: Array,
  },
  components: {},
  created() {
    this.title = this.name + " —— 关联子节点";
    this.selectListDict(true)
  },

  methods: {
    selectListDict(cb) {
      if (cb) {
        let info = { condition: 1 }; //1:全部查询，2:只查最大一级
        selectListDict(info).then((response) => {
          if (response.success) {
            this.dictList = response.data;
          }
        });
      }
    },
    //弹窗关闭
    dialogClose() {
      this.formData = {};
      this.$emit("listenAssociatedChildNodeClose", {
        type: "cancel",
        isClose: true,
      });
    },
    associatedChildNode() {
      associatedChildNode(this.formData).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "关联成功!" });
          this.formData = {};
          this.$emit("listenAssociatedChildNodeClose", {
            type: "sure",
            isClose: true,
          });
        }
      });
    },
    //确定
    sure() {
      console.log(this.formData);
      this.$refs["associatedChildNode"].validate((valid) => {
        if (valid) {
          this.associatedChildNode();
        } else {
          console.log("-------------error");
        }
      });
    },
  },

  watch: {
    // childrenIdList(val, oldVal) {
    //   console.log(this.childrenIdList);
    //   console.log("aaaa");
    //   if (val) {
    //     val.map(function (item, index) {
    //       this.formData.childrenIdList.push(element.id);
    //     });
    //   }
    // },

    childrenIdList: {
      handler(val, oldVal) {
        let that = this;

        console.log("aaaa");
        if (val) {
          val.map(function (item, index) {
            that.formData.childrenIdList.push(item.id);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}

.addDailog {
  // padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  .roleCodeList {
    width: 100% !important;
    height: auto;
    text-align: center !important;
  }
  .el-form-item {
    text-align: left;

    // display: flex;
    width: 100%;
    // float: left;
    min-height: 1rem;
    .el-select {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left !important;
      .el-input__inner {
        text-align: left !important;
      }
    }
  }
}
</style>