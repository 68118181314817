<template>
  <div>
    <el-main>
      <el-form>
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <span>组别：</span>
            <el-select
              @visible-change="selectGroupOptions($event)"
              @change="loadData($event)"
              v-model="query.group"
              filterable
              placeholder="请选择"
              clearable
              class="childWidth"
            >
              <el-option
                v-for="(item, index) in query.groupOptions"
                :key="index"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-form-item>
              <el-button
                type="primary"
                @click="openDialog('add')"
                v-permission="['admin:dataManagement:add']"
                >新增</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <!-- 表格 -->

      <el-table
        border
        style="width: 100%"
        :data="tableData"
        :span-method="objectSpanMethod"
        v-loading="tableLoading"
      >
        <!-- <el-table-column type="index" label="#" width="40"></el-table-column> -->
        <el-table-column label="组别" prop="groupName">
          <template slot-scope="scope">
            <p>{{ scope.row.groupName }}</p>
            <span class="workflowVersion">{{ scope.row.group }} </span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="组别" prop="group"></el-table-column> -->
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="助记码" prop="code"></el-table-column>
        <!-- <el-table-column label="编号" prop="value"></el-table-column> -->
        <el-table-column label="启用状态" prop="enabled">
          <template slot-scope="scope">
            <span>{{ scope.row.enabled == 1 ? "启用" : "禁用" }} </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="200">
          <template slot-scope="scope">
            <el-button
              v-permission="['admin:dataManagement:edit']"
              type="primary"
              size="mini"
              @click="openDialog('edit', scope.row.id, scope.row.name)"
              >编辑</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="addChildren(scope.row.id, scope.row.name, scope.row.children)"
              >关联子节点</el-button
            >
            <!-- <el-button
              type="primary"
              size="mini"
              @click="deleteDict(scope.row.id)"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="this.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.total"
        ></el-pagination>
      </div>
    </el-main>

    <!-- 编辑 -->
    <edit
      v-if="editDialog"
      :editDialog="editDialog"
      :type="typeDialog"
      v-on:listenEditClose="listenEditClose"
      :id="dictInfo.id"
      :name="dictInfo.name"
    ></edit>
    <associatedChildNode
      v-if="associatedChildNodeDialog"
      :associatedChildNodeDialog="associatedChildNodeDialog"
      v-on:listenAssociatedChildNodeClose="listenAssociatedChildNodeClose"
      :id="dictInfo.id"
      :name="dictInfo.name"
      :childrenIdList="dictInfo.childrenIdList"
    ></associatedChildNode>
  </div>
</template>

<script>
import { getDictList, deleteDict, selectListDict } from "@/api/dataManagement";
import edit from "@/components/dataManagement/edit";
import associatedChildNode from "@/components/dataManagement/associatedChildNode";

export default {
  name: "dataManagement",
  data() {
    return {
      tableData: [],
      editDialog: false, //打开编辑
      associatedChildNodeDialog: false,
      buttonControlDialog: false,
      dictInfo: {
        id: "",
        name: "",
        childrenIdList:[]
      },
      typeDialog: "",
      roleCode: null,
      roleName: null,
      tableLoading: false,
      indexOf: [0],
      spanArr: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageCount: 1,
      query: {
        groupOptions: [], // 查询条件中组别选项
        group: "", // 查询条件中组别选中项
      },
    };
  },
  components: {
    edit,
    associatedChildNode,
  },

  mounted() {
    this.loadData();
  },

  methods: {
    listenAssociatedChildNodeClose(params) {
      const { type, isClose } = params;
      this.associatedChildNodeDialog = !isClose;
      if (type == "sure") {
        this.queryDictList(this.pageNum, this.pageSize);
      }
    },
    addChildren(id, name, children) {
      this.dictInfo.id = String(id);
      this.dictInfo.name = String(name);
      this.dictInfo.childrenIdList = children;
      this.associatedChildNodeDialog = true;
    },
    loadData() {
      this.queryDictList(1, this.pageSize);
    },
    handleSizeChange(val) {
      this.queryDictList(1, val);
    },
    handleCurrentChange(val) {
      if (val > this.pageCount) {
        val = this.pageCount;
      }
      this.queryDictList(val, this.pageSize);
    },
    deleteDict(id) {
      this.$confirm("确定要删除此条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          deleteDict(id).then((response) => {
            if (response.success) {
              this.queryDictList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getSpanArr(data) {
      this.spanArr = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          if (data[i].group === data[i - 1].group) {
            // 如果group相等就累加，并且push 0  这里是根据一样的group匹配
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            // 不相等push 1
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
      this.tableLoading = false;
    },
    // 3、合并行数
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      for (let i = 0; i < this.indexOf.length; i++) {
        if (columnIndex === this.indexOf[i]) {
          const _row = this.spanArr[rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col,
          };
        }
      }
    },
    queryDictList(num, pageSize) {
      let query = {};
      query.pageNum = num;
      query.pageSize = pageSize;
      if (this.query.group) {
        query.group = this.query.group;
      }
      this.tableLoading = true;
      getDictList(query).then((response) => {
        this.tableData = response.data;
        if (response.total != null) {
          this.pageNum = response.pageNum;
          this.pageSize = response.pageSize;
          this.total = response.total;
          this.pageCount = response.pageCount;
        } else {
          this.total = 0;
        }
        this.getSpanArr(this.tableData);
      });
    },
    openButtonControlDialog(code, name) {
      this.roleCode = code;
      this.roleName = name;
      this.buttonControlDialog = true;
    },
    //新增编辑弹窗显示
    openDialog(type, id, name) {
      // console.log(id);
      if (type == "edit") {
        this.typeDialog = "edit";
        this.dictInfo.id = String(id);
        this.dictInfo.name = name;
      } else if (type == "add") {
        this.typeDialog = "add";
      }
      this.editDialog = true;
    },
    //监听编辑弹窗关闭
    listenEditClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.editDialog = !isClose;
      if (type == "sure") {
        this.queryDictList(this.pageNum, this.pageSize);
      }
    },
    //监听编辑弹窗关闭
    listenbuttonControlClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.buttonControlDialog = !isClose;
      if (type == "sure") {
          this.queryDictList(this.pageNum, this.pageSize);
      }
    },

    selectGroupOptions(cb) {
      if (cb) {
        let info = { condition: 1 }; //1:全部查询，2:只查最大一级
        selectListDict(info).then((response) => {
          if (response.success) {
            this.query.groupOptions = [];
            for (const item of response.data) {
              if (item.code) {
                this.query.groupOptions.push(item);
              }
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
